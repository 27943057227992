<template>
    <modal ref="modalEnviarPedido" titulo="Enviar Pedido" tamano="modal-xl" no-aceptar :cargado-modal="loading" adicional="Enviar" @adicional="enviar">
        <div class="row mx-0 text-general justify-center f-16 my-3 mb-5">
            ¿Desea Enviar el pedido?
        </div>
        <div class="row mx-0 text-general justify-center f-16 my-3 mb-4">
            Confirmar la fecha y hora de la entrega y la forma de pago
        </div>
        <div class="row mx-0 mb-4">
            <div class="col">
                <p class="text-general f-15 pl-3">
                    Instrucciones de la entrega
                </p>
                <p class="border br-7 p-2 f-12 ml-3 bg-whitesmoke" v-html="formatearTextoHtml(pedido.instrucciones) || 'No registra instrucciones' " />

                <div class="col mt-3">
                    <p class="text-general f-15">Forma de pago</p>
                    <el-select v-model="pedido.pago_teorico" :disabled="disableSelectNomina" size="medium" placeholder="Seleccionar" @change="putFormaPagoPedido">
                        <el-option label="Efectivo" :value="1" />
                        <el-option label="Transferencia" :value="2" />
                        <el-option label="Credito" :value="3" />
                        <el-option label="En Linea" :value="4" />
                        <el-option label="Nomina" :value="5" :disabled="disableOptionNomina" />
                    </el-select>
                </div>
                <div class="row mx-0 px-2 mt-3 text-general align-items-center">
                    <div class="col-auto px-2">
                        <el-checkbox v-model="model.otra_direccion" :true-label="1" :false-label="0" class="check-red" />
                    </div>
                    <div class="col px-2">
                        Este Pedido se enviara a una direccion diferente a la registrada.
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="row mx-0 justify-center">
                    <div class="col-5 px-0 mx-2">
                        <p class="text-general text-center">
                            Fecha Entrega
                        </p>
                        <el-date-picker
                        v-model="model.entrega_fecha"
                        type="date"
                        size="small"
                        class="w-100"
                        placeholder="Fecha"
                        value-format="yyyy-MM-dd"
                        value="yyyy-MM-dd"
                        :clearable="false"
                        :picker-options="pickerOptions"
                        @change="set_franja"
                        />
                    </div>
                    <div class="col-5 px-0 mx-2">
                        <p class="text-general text-center">
                            Hora Entrega
                        </p>
                        <el-select v-model="model.entrega_horario" class="w-100" size="small" placeholder="Horario">
                            <el-option
                            v-for="item in horarios"
                            :key="item.hora"
                            :label="item.horario"
                            :value="item.hora"
                            :disabled="item.disabled"
                            />
                        </el-select>
                    </div>
                </div>
                <div v-if="pedido.id_pedido != null" class="row mx-0 justify-center pt-4">
                    <div class="col-auto" :class="!model.cliente_entrega_directa ? 'text-danger' : 'text-general2'">
                        Enviar al {{$config.vendedor}}
                    </div>
                    <div class="col-auto px-0">
                        <el-switch
                        v-model="model.cliente_entrega_directa"
                        active-color="#000000"
                        inactive-color="#ff4949"
                        :active-value="1"
                        :inactive-value="0"
                        />
                    </div>
                    <div class="col-auto" :class="model.cliente_entrega_directa ? 'text-general' : 'text-general2'">
                        Enviar al Cliente
                    </div>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import moment from 'moment'
import Pedidos from '~/services/pedidos/pedidos-admin'
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            pickerOptions: {
                disabledDate(time){
                    return time.getTime() < moment().subtract(1,'days');
                }
            },
            horarios:[],
            model:{
                entrega_fecha:'',
                entrega_horario:'',
                cliente_entrega_directa:0,
                otra_direccion:0,
            },
            loading:false,
            hora: ''
        }
    },
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_admin/id_pedido',
            pedido: 'pedidos/pedidos_admin/pedido',
        }),
        disableSelectNomina(){
            if(this.pedido.pago_teorico === 5){
                return true
            }
            return false
        },
        disableOptionNomina(){
            if(this.pedido.pago_teorico !== 5){
                return true
            }
            return false
        }
    },
    methods: {
        toggle(){
            this.model.entrega_fecha = this.pedido.entrega_fecha
            this.model.entrega_horario = this.pedido.entrega_horario
            this.model.cliente_entrega_directa = this.pedido.cliente_entrega_directa
            this.model.otra_direccion = this.pedido.otra_direccion
            this.set_franja(this.model.entrega_fecha)
            this.$refs.modalEnviarPedido.toggle();
        },
        async enviar(){
            try {

                if(this.model.entrega_horario === ''){
                    this.notificacion('Advertencia','Por favor seleccione un horario','warning')
                    return
                }
                if(this.id_pedido === null || this.id_pedido === ''){
                    this.notificacion('Advertencia','Por favor seleccione un pedido','warning')
                    return
                }
                this.loading = true
                const {data} = await Pedidos.enviar_pedido(this.id_pedido,this.model)

                this.$emit('accion-enviar')
                this.$refs.modalEnviarPedido.toggle();
                this.notificacion('Pedido Enviado','','success')

            } catch (e){
                this.error_catch(e)
            } finally{
                this.loading = false
            }
        },
        async set_franja(fecha){
            try {
                let id_cedis = this.pedido.id_cedis
                const vistaAdmin = true
                const {data} = await Pedidos.setFranjasHorarios(id_cedis, fecha, vistaAdmin)
                this.horarios = data?.data

                if (this.hora) this.model.entrega_horario = this.hora
                if (this.model.entrega_horario != null && this.model.entrega_horario != ''){
                    let filtered = this.horarios.filter(o=>o.hora === this.model.entrega_horario)
                    
                    if (filtered.length < 1){
                        let horaMostrar = moment(this.model.entrega_horario,'H').format('hh:mm A')
                        let fechaHoy = moment().format('YYYY-MM-DD')
                        let obj = {
                            'hora':  this.model.entrega_horario,
                            'horario': horaMostrar+' - '+horaMostrar
                        }
                        if (this.model.entrega_fecha < fechaHoy){
                            this.horarios.push(obj)                            
                        }else{
                            this.hora = this.model.entrega_horario
                            this.model.entrega_horario = ''
                        }
                    }
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        async putFormaPagoPedido(value){
            try {
                const form = {
                    formaPago: value,
                };
                const {data} = await Pedidos.putFormaPagoPedido(this.id_pedido, form)
                this.notificacion('Mensaje', 'Forma pago actualizada', 'success');
            } catch (e){
                this.error_catch(e)
            }
        },

    }
}
</script>
